import React from "react";

const Telegram = () => {
  return (
    <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.24587 10.448L19.222 3.32891C20.8998 2.59014 26.5836 0.210708 26.5836 0.210708C26.5836 0.210708 29.2041 -0.825495 28.9873 1.69785C28.9118 2.73406 28.3274 6.37036 27.743 10.3041L25.9238 21.9518C25.9238 21.9518 25.7824 23.6596 24.5382 23.957C23.294 24.2545 21.258 22.9208 20.8904 22.6234C20.5982 22.4027 15.4233 19.0639 13.5287 17.4328C12.8972 17.001 12.7369 16.1279 13.1611 15.4851C13.2742 15.3124 13.425 15.1685 13.6041 15.063C16.2245 12.6164 19.3634 9.57492 21.2486 7.64643C22.1252 6.75414 23.0018 4.68173 19.3539 7.20508L9.09853 14.2282C8.0334 14.7176 6.82688 14.7463 5.7429 14.305C3.55608 13.6334 1.01108 12.7507 1.01108 12.7507C1.01108 12.7507 -0.742149 11.6377 2.24587 10.448Z" />
    </svg>
  );
};

export default Telegram;
