import React, { useState, useEffect} from 'react';
import styled from 'styled-components';

import { StyledThemeProvider } from '../styles/themeManager';
import SEO from "../components/seo";
import Header from "../components/newHeader";
import Footer from "../components/newFooter";

import { isRestrictedUser } from '../contexts/Application'

const Content = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.colors.white};
`;

const RestrictionModal = styled.div`
  position: absolute;
  top: 40vh;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  filter: blur(0) !important;
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.backgroundColor};
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 10px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Titillium Web', sans-serif;
`;


const NewLayout = ({children}) => {

  const [restrictedUser, setRestrictedUser] = useState(false)

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await isRestrictedUser()
      setRestrictedUser(response)
    }
    fetchMyAPI()
  }, [])

  return (
    <StyledThemeProvider>
      <SEO
        title="Opium finance is decentralized finance platform"
        description={'Opium.finance is a decentralized finance platform where people create markets. Discover the true financial independence – be your own banker and hedge fund manager with a wide range of сutting-edge financial tools:  first decentralized low-cost insurance, Turbos, Сall Options.'}
      />
      <Header />
      <Content>
        {children}
      </Content>
      <Footer />
      {restrictedUser && <RestrictionModal >Based on your IP address, you are breaking our Terms of Service related to prohibited jurisdictions, sanction lists, and black listed VPNs</RestrictionModal>}
    </StyledThemeProvider>
  )
}

export default NewLayout;