import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from './theme'
import { useDarkMode, isRestrictedUser} from '../contexts/Application'

export const StyledThemeProvider = props => {
  const [isDarkMode] = useDarkMode()
  const [restrictedUser, setRestrictedUser] = useState(false)

  useEffect(() => {
    async function fetchCloudflare() {
      let response = await isRestrictedUser()
      setRestrictedUser(response)
    }
    fetchCloudflare()
  }, [])

  return (
    <ThemeProvider theme={theme(isDarkMode)}>
      <GlobalStyle isDark={isDarkMode} restrictedUser={restrictedUser}/>
      {props.children}
    </ThemeProvider>
  )
}
