import React from "react";

const Subtract = () => {
  return (
    <svg width="29" height="21" viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5846 19.0022C20.3227 19.9126 21.2085 20.9705 21.2085 20.9705C26.3599 20.8074 28.4859 17.6187 28.6953 17.3047C28.7069 17.2873 28.7126 17.2787 28.7126 17.28C28.7126 9.43138 25.1697 3.059 25.1697 3.059C21.6513 0.426397 18.2806 0.500208 18.2806 0.500208L17.9362 0.89387C22.1188 2.14866 24.0625 3.99394 24.0625 3.99394C21.5037 2.61613 18.9941 1.92723 16.6568 1.65659C14.8853 1.45976 13.1876 1.50896 11.6868 1.70579C11.5579 1.70579 11.4478 1.72454 11.3237 1.74568C11.3056 1.74875 11.2873 1.75188 11.2685 1.755C10.4074 1.85342 8.31608 2.14866 5.68347 3.30504C4.77313 3.6987 4.23185 3.99394 4.23185 3.99394C4.23185 3.99394 6.24936 2.05025 10.678 0.795455L10.432 0.500208C10.432 0.500208 7.08589 0.426397 3.54294 3.059C3.54294 3.059 0 9.43138 0 17.28C0 17.28 2.06672 20.8229 7.50415 20.9952C7.50415 20.9952 8.41449 19.9126 9.15261 18.9776C6.02793 18.0427 4.84695 16.099 4.84695 16.099C4.84695 16.099 5.09298 16.2712 5.53585 16.5173C5.55106 16.5173 5.56626 16.5267 5.58728 16.5397C5.60027 16.5477 5.61547 16.5571 5.63427 16.5665C5.67117 16.5911 5.70808 16.6095 5.74498 16.628C5.78189 16.6464 5.81879 16.6649 5.8557 16.6895C6.47079 17.0339 7.08589 17.3046 7.65177 17.526C8.66053 17.9443 9.86612 18.3133 11.2685 18.584C13.1138 18.9284 15.2789 19.0515 17.6409 18.6086C18.7973 18.3872 19.9783 18.0673 21.2085 17.5506C22.0696 17.2308 23.0291 16.7633 24.0379 16.099C24.0379 16.099 22.8077 18.0919 19.5846 19.0022ZM16.2372 12.2855C16.2372 10.7847 17.3444 9.5791 18.7468 9.5791C20.1246 9.5791 21.2564 10.7847 21.2564 12.2855C21.2564 13.7863 20.1492 14.9919 18.7468 14.9919C17.369 14.9919 16.2372 13.7863 16.2372 12.2855ZM7.25684 12.2855C7.25684 10.7847 8.36401 9.5791 9.76642 9.5791C11.1688 9.5791 12.3006 10.7847 12.276 12.2855C12.276 13.7863 11.1688 14.9919 9.76642 14.9919C8.38861 14.9919 7.25684 13.7863 7.25684 12.2855Z" />
    </svg>
  );
};

export default Subtract;
