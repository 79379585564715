import React from "react";

const MShape = () => {
  return (
    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.87955 4.78395C3.91496 4.45924 3.78155 4.1383 3.52031 3.91975L0.859243 0.944444V0.5H9.12185L15.5084 13.5L21.1232 0.5H29V0.944444L26.7248 2.96914C26.5286 3.10791 26.4313 3.33599 26.472 3.56173V18.4383C26.4313 18.664 26.5286 18.8921 26.7248 19.0309L28.9468 21.0556V21.5H17.7703V21.0556L20.0721 18.9815C20.2983 18.7716 20.2983 18.7099 20.2983 18.3889V6.3642L13.8985 21.4506H13.0336L5.58263 6.3642V16.4753C5.52051 16.9004 5.67262 17.3284 5.9951 17.6358L8.98879 21.0062V21.4506H0.5V21.0062L3.4937 17.6358C3.81382 17.3279 3.95707 16.8971 3.87955 16.4753V4.78395Z" />
    </svg>
  );
};

export default MShape;
