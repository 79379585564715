import React from "react";

const Twitter = () => {
  return (
    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M27.6887 0.966093C26.8606 1.44712 24.9594 2.14611 24.0217 2.14611V2.14755C22.951 1.13323 21.4437 0.5 19.772 0.5C16.5252 0.5 13.8925 2.88761 13.8925 5.83091C13.8925 6.23986 13.9447 6.63875 14.0413 7.02127H14.0404C9.63617 6.91645 4.81935 4.91452 1.91963 1.48819C0.136799 4.28675 1.6796 7.3992 3.70278 8.53327C3.01023 8.58066 1.73533 8.4609 1.13525 7.92991C1.09503 9.78738 2.07987 12.2485 5.67087 13.1414C4.97927 13.4788 3.75503 13.382 3.22272 13.3102C3.40955 14.8782 5.83078 16.9281 8.47843 16.9281C7.53477 17.918 3.9843 19.7135 0 19.1423C2.70592 20.6353 5.8596 21.5 9.19758 21.5C18.6833 21.5 26.0499 14.5281 25.6535 5.92741C25.6519 5.91793 25.6519 5.90845 25.6509 5.89811C25.6519 5.876 25.6535 5.85389 25.6535 5.83091C25.6535 5.8042 25.6509 5.77893 25.65 5.75309C26.5135 5.2175 27.6722 4.27009 28.5 3.02316C28.0199 3.26295 26.5797 3.74312 25.2396 3.8623C26.0997 3.44129 27.3739 2.06254 27.6887 0.966093Z" />
    </svg>
  );
};

export default Twitter;
